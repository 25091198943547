html {
    scroll-padding-top: 75px;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
    color: #353740;
    font-size: 16px;
    background-color: #f7f7f7 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
    margin: 0;
    color: #202123;
}

h1 {
    font-weight: 500;
    font-size: 28px;
}

h2 {
    font-weight: 500;
    font-size: 20px;
}

h3 {
    font-weight: 500;
    font-size: 18px;
}

li, ul {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
}

a {
    text-decoration: none;
    cursor: pointer;
}
