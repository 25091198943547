@use '../../../Card.module' as Card;

.cardContainer {
  @include Card.cardContainer;
  @include Card.hoverable;

  ul li {
    list-style-type: disc;
    margin-left: 24px;
  }
  p, li, code {
    font-size: 0.875rem;
    line-height: 1.43;
    color: #333;
  }
  h1, h2, h3, h4, h5, h6 {
    color: #333;
  }
  h1 {
    font-size: 1.2rem;
    line-height: 1.2;
  }
  h2 {
    font-size: 1.1rem;
    line-height: 1.2;
  }
  h3, h4, h5, h6 {
    font-size: 1rem;
    line-height: 1.2;
  }
  code {
    white-space: pre-wrap;
  }
}

.renderedExample {
  max-height: 34rem;
  line-height: 20px;
  overflow: hidden;

  --mask: linear-gradient(to bottom,
          rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 28rem,
          rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 100%
  );
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.training {
  border-bottom: 3px solid #ccc;
}

.validation {
  border-bottom: 3px solid #FFCB87;
}

.textBlock {
  display: block; /* For some reason if the parent is flex it affects line height */
  white-space: pre-wrap;
  color: #333;
}

.markdown {
  white-space: normal;

  ul {
    padding-left: 24px;
    li {
      list-style-type: disc;
    }
  }
}

.textBlock p:first-child {
  margin-top: 0;
}

.textBlock p:last-child {
  margin-bottom: 0;
}