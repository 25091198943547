$topNavHeight: 60px;
$sidebarWidth: max(min(250px, 20vw), 120px);
$containerWidth: 1200px;

.grayContainer {
  background-color: #f8f8f9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  padding-bottom: 80px;

  .loginSignUpForm {
    margin-top: calc(50vh - 32px);
    transform: translateY(-50%);
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 3px 3px 18px rgba(0, 0, 0, 0.15);
    padding: 32px 48px;
  }
}

.mainContainer {
  top: $topNavHeight;
}

@media screen and (max-width: 640px) {
  $sidebarWidth: 180px;
}

@media screen and (max-width: calc($containerWidth + ($sidebarWidth * 2))) {
  .mainContainer {
    margin-left: $sidebarWidth !important;
  }
}

@media screen and (max-width: calc($containerWidth + $sidebarWidth)) {
  .mainContainer {
    max-width: calc(100vw - $sidebarWidth) !important;
  }
}
