@mixin cardContainer {
  box-shadow: 0 0.5px 2.5px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0 0;
  background-color: #fff;
}

@mixin hoverable {
  cursor: pointer;
  transition: transform 100ms ease-out !important;

  &:hover {
    transform: scale(1.025);
  }
}

.cardContainer {
  @include cardContainer;
}

.hoverable {
  @include hoverable;
}