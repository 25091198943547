.card {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  transition: 200ms transform ease-in-out;
  padding-bottom: 50%;

  h2 {
    font-size: 2.5rem;
    font-weight: 400;
  }

  .inside {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  .inside img {
    height: 32px;
  }
}

.card:hover {
  transform: scale(1.03);
}

.openAi {
  background-color: #85FFBD;
  background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);

  h2, h3, span {
    color: #333;
  }
}

.ai21 {
  background-color: #e91e63;
  background-image: linear-gradient(160deg, #F52067 0%, #B5184C 100%);

  h2, h3, span {
    color: #fff;
  }
}

.replicate {
  background-color: #fafafa;
  background-image: linear-gradient(180deg, #fafafa 0%, #ddd 100%);
  h2, h3, span {
    color: #000;
  }
}

.anthropic {
  background-color: #d97757;
  background-image: linear-gradient(180deg, #f58662 0%, #d97757 100%);
  h2, h3, span {
    color: #fff;
  }
}

.googleAi {
  background-color: #89b3ff;
  background-image: linear-gradient(180deg, #99b7f9 0%, #6692f8 100%);
  h2, h3, span {
    color: #fff;
  }
}

.groqCloud {
  background-color: #121418;
  background-image: linear-gradient(180deg, #333741 0%, #121418 100%);
  h2, h3, span {
    color: #fff;
  }
  .inside img {
    height: 24px;
  }
}