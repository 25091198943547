$horizontalPadding: 48px;
$verticalPadding: 18px;

.modal {
  outline: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  box-sizing: border-box;
  max-height: 94vh;
  max-width: 94vw;
  height: 98vh;
  background-color: #f7f7f7;
  overflow-y: hidden;

  .header {
    position: relative;
    z-index: 1000;
    background-color: white;
    padding: $verticalPadding $horizontalPadding;
    border-bottom: 1px solid #ddd;
  }

  .container {
    padding: $verticalPadding*1.5 $horizontalPadding;
    overflow-y: scroll;
    overflow-x: hidden;

    .MuiInputBase-root {
      background-color: #fff;
    }
  }
}