.in {
  animation: slideUp ease-in-out 300ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.out {
  animation: slideDown ease-in-out 300ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}

@keyframes slideUp {
  0% {
    transform: translateY(180px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(180px);
    opacity: 0;
  }
}