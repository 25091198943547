@use "../Layout.module.scss" as Layout;

.navBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1200; // Under the Example Editor but above Fabs
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ececf1;
  height: Layout.$topNavHeight;
  padding: 0 24px;
  font-size: 15px;

  .logoIcon {
    height: 32px;
    width: 32px;
  }

  .logoIcon svg {
    fill: #353740;
  }

  .organizationLogo {
    max-width: 64px;
    height: 32px;
    object-fit: contain;
  }

  a.getStarted {
    text-decoration: none;
    height: 30px;
    line-height: 30px;
    padding: 0px 20px;
    border-radius: 15px;
    color: white;
    font-weight: 500;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    background-color: #286fff;
    transition: 100ms transform ease-in-out;
  }

  a.getStarted:hover {
    transform: scale(1.04);
  }

  a.navLink {
    font-size: 1.0rem;
    font-weight: 400;
  }
}

.sidebar {
  width: #{Layout.$sidebarWidth};
  box-sizing: border-box;
  height: calc(100vh - #{Layout.$topNavHeight});
  position: fixed;
  top: Layout.$topNavHeight;
  //border-right: 1px solid #ececf1;
  z-index: 999;
  background-color: #f7f7f7;
  padding: 32px 24px;
}

a.navLink, a.navLink:visited {
  color: #444 !important;
}

a.navLink:hover {
  color: #313847 !important;
}

a.navLink.current {
  font-weight: 600;
}

@media screen and (max-width: 560px) {
  .sidebar {
    padding: 32px 12px;
  }
}