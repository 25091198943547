@mixin transformCenteredModal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -51%);
}

@mixin modalBase {
  background-color: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: scroll;
  outline: 0;
}

.default {
  @include transformCenteredModal;
  @include modalBase;
  max-width: 480px;
  padding: 32px
}

.large {
  @include transformCenteredModal;
  @include modalBase;
  max-width: 640px;
  padding: 32px
}
.xl {
  @include transformCenteredModal;
  @include modalBase;
  max-width: 800px;
  padding: 32px
}

/* @hello-pangea/dnd drag position is incorrect when parent uses transform */
.flexCenteredModalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fieldOrderModal {
  @include modalBase;
  width: 100%;
  max-width: 720px;
  padding: 32px
}

/* New Modal Styles */

.small {
  @include transformCenteredModal;
  @include modalBase;
  max-width: 480px;
}

.newLarge {
  @include transformCenteredModal;
  @include modalBase;
  max-width: 740px;
}
