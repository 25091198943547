.coolGradient {
  background-image: radial-gradient(circle at 60% -7.92%,#bc4aee 0,#9e42f0 25%,#783cf2 50%,#3d39f3 75%,#0038f5 100%);
  background-clip: text;
  color: transparent;
}

.primaryButton {
  height: 56px;
}

.organizationLogo {
  max-width: 64px;
  height: 32px;
  object-fit: contain;
}